<template>
<div>
  <b-card
    title="配置条件"
  >
      <div class="mb-2">
        <b-col sm="2">
          <label
            for="textarea-default"
            class="text-nowrap"
          >备注/说明</label>
        </b-col>
        <b-col
          sm="10"
          class="mb-1"
        >
          <b-form-textarea
            id="textarea-default"
            v-model="ruleInfo.note"
          />
        </b-col>
      </div>
      <div class="mt-2">
        <div class="d-flex mb-2 w-25" style="line-height:40px;">
          <div>符合以下</div>
          <div style="flex:1" class="ml-50 mr-50">
            <v-select
              v-model="ruleInfo.type"
              label="description"
              :options="ruleEnumInfo.ruleConditionList"
              class="w-100"
            />
          </div>
          <div>规则</div>
        </div>
        <b-form
          ref="form"
          class="repeater-form"
          @submit.prevent="repeateAgain"
        >
          <!-- Row Loop -->
          <b-row
            v-for="(item, index) in ruleInfo.ruleLineList"
            :key="index"
            ref="row"
          >
            <!-- Item Name -->
            <b-col md="4">
              <v-select
                v-model="item.ruleType"
                label="description"
                :options="ruleEnumInfo.ruleTypeEnumList"
                @input="(val)=>ruleTypeHandleChange(item,val)"
                class="w-100"
              />
            </b-col>
            <!-- Cost -->
            <b-col md="2">
              <v-select
                v-model="item.ruleTypeCondition"
                label="description"
                :options="getTypeConditionByRuleType(item.ruleType)"
                class="w-100"
              />
            </b-col>
            <!-- Cost -->
            <b-col md="3">
              <v-select
                v-if="item.ruleType && item.ruleType.enumName === 'TAG'"
                :value="item.ruleLabel"
                label="name"
                :options="tagOperations"
                @input="(val)=>ruleValueHandleChange(index,val)"
                class="w-100"
                :class="item.invalid == true? 'input-red' : 'input-black'"
              >
                <li slot="list-footer" class="select-pagination">
                  <button :disabled="!hasTagPrevPage" @click.stop.prevent="pageTag(false,true)">上一页</button>
                  <button :disabled="!hasTagNextPage" @click.stop.prevent="pageTag(true,false)">下一页</button>
                </li>
              </v-select>
              <b-form-input
                :class="item.invalid == true ? 'input-red' : 'input-black'"
                v-else-if="item.ruleType && item.ruleType.enumName === 'GROUP'"
                v-model="item.ruleLabel"
                @click="openGroupTreeModal(item,index)"
              />
            </b-col>
            <!-- Remove Button -->
            <b-col
              lg="2"
              md="3"
              class="mb-50"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0"
                @click="removeItem(index)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-25"
                />
                <span>删除</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="addRule"
          class="mt-2"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>添加规则</span>
        </b-button>
      </div>
      <div class="mt-2">
        <v-select
          class="w-25"
          v-model="ruleInfo.businessType"
          label="description"
          :options="ruleEnumInfo.ruleBusinessEnumList"
          placeholder="请选择业务"
        />
      </div>
      <div class="mt-2">
        <v-select
          placeholder="请选择人员"
          v-model="ruleInfo.userList"
          :options="adminOperations"
          label="userName"
          class="w-25"
          multiple>
          <li slot="list-footer" class="select-pagination">
            <button :disabled="!hasAdminPrevPage" @click.stop.prevent="pageAdmin(false,true)">上一页</button>
            <button :disabled="!hasAdminNextPage" @click.stop.prevent="pageAdmin(true,false)">下一页</button>
          </li>
      </v-select>
      </div>
    <!-- 修改权限的弹窗 -->
    <template>
      <b-modal
        id="modal-groupTree-closing"
        title="选择分组"
        @ok="groupChangeHandle"
      >
      <el-tree
        ref="groupTree"
        :data="groupTree"
        :check-strictly="true"
        show-checkbox
        node-key="id"
        :props="groupProps"
        highlight-current
        :default-checked-keys="groupTreeDefaultCheckedKeys"
        @node-click="groupTreeNodeClick"
        @check-change="groupTreeHandleCheckChange"
        default-expand-all/>
      </b-modal>
    </template>
    </b-card>

    <div>
      <b-button
        class="mb-2 mr-2"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="saveRule"
        :disabled="!edit"
        v-if="$checkButtonPermission('205002001')"
      >
        保存
      </b-button>
      <b-button
        class="mb-2 mr-2"
        variant="outline-secondary"
        type="reset"
        @click="back"
      >
       取消
      </b-button>
    </div>
  </div>
</template>

<script>
import {BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BCard,BFormTextarea,BModal} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import vSelect from 'vue-select'
import request from '@/api/request'
export default {
  components: {
    BCard,BFormTextarea,vSelect,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BModal,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      // 当前页面是否可编辑
      edit: this.$route.query.edit,
      // 当前选中的分组Id,保证单选
      editCheckdGroupTreeId: null,
      // 当前选择的分组
      editCheckdGroup:{},
      // 分组显示的参数控制
      groupProps: {
        label: 'name',
        children: 'childNodes'
      },
      // 当前规则的详情
      ruleInfo:{
        id: "",
        note: "",
        ruleLineList: [],
        type:{},
        userList:[],
        businessType: {}
      },
      // 成员列表
      adminOperations: [
      ],
      // 成员分页参数
      adminSearchCurrentPage: 1,
      adminSearchPageSize: 5,
      // 标签分页参数
      tagSearchCurrentPage: 1,
      tagSearchPageSize: 5,
      // 标签列表
      tagOperations:[],
      // 成员总量
      adminTotal: 0,
      // 标签总量
      tagTotal: 0,
      // 枚举列表
      ruleEnumInfo:{
        ruleConditionList: [],
        ruleTypeEnumList: [],
        ruleTypeConditionEnumList: [],
        ruleBusinessEnumList: []
      },
      // 分组树
      groupTree:[],
      // 分组默认选中的key
      groupTreeDefaultCheckedKeys:[],
      // 当前正在操作的规则行
      nowChangeGroupIndex: null,
      // 是否查询过分组
      groupTreeLock: false,
    }
  },
  computed: {
    /**
     * 计算属性
     */
    hasAdminNextPage() {
      return (this.adminSearchCurrentPage * this.adminSearchPageSize) < this.adminTotal
    },
    hasAdminPrevPage() {
      return this.adminSearchCurrentPage > 1
    },
    hasTagNextPage() {
      return (this.tagSearchCurrentPage * this.tagSearchPageSize) < this.tagTotal
    },
    hasTagPrevPage() {
      return this.tagSearchCurrentPage > 1
    },
  },
  mounted() {
    this.initTrHeight()
    // 获取角色详情
    if(this.$route.query && this.$route.query.id){
      this.getRuleInfo(this.$route.query.id)
    }
    // 获取所有的枚举
    this.getRuleEnumInfo()
    // 获取第一页的成员
    this.pageAdmin()
    // 获取第一页的标签
    this.pageTag()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    // 根据类型获取条件枚举(前端硬关联)
    getTypeConditionByRuleType(ruleType){
      let typeConditionArray = []
      if(!ruleType){
        return typeConditionArray
      }
      switch(ruleType.enumName){
        case 'TAG':
          this.ruleEnumInfo.ruleTypeConditionEnumList.forEach(element => {
            if(element.enumName == 'EQUALS'){
              typeConditionArray.push(element)
            }
          })
          break
        case 'GROUP':
          this.ruleEnumInfo.ruleTypeConditionEnumList.forEach(element => {
            if(element.enumName == 'EQUALS_IN'){
              typeConditionArray.push(element)
            }
          })
          break
      }
      return typeConditionArray
    },
    // 保存规则
    saveRule(){
      console.log('rule',this.ruleInfo)
      if(this.ruleInfo.note && this.ruleInfo.note.length > 100){
        this.$bvToast.toast("规则备注不能超过100个字符",{
          title: `提示`,
          variant: 'danger',
          solid: true
        })
        return
      }
      if(!this.ruleInfo.type || !this.ruleInfo.type.enumName){
        this.$bvToast.toast("请选择规则类型",{
          title: `提示`,
          variant: 'danger',
          solid: true
        })
        return
      }
      if(!this.ruleInfo.businessType || !this.ruleInfo.businessType.enumName){
        this.$bvToast.toast("请选择规则关联的业务类型",{
          title: `提示`,
          variant: 'danger',
          solid: true
        })
        return
      }
      if(!this.ruleInfo.userList || this.ruleInfo.userList.length <= 0){
        this.$bvToast.toast("请选择规则关联的用户",{
          title: `提示`,
          variant: 'danger',
          solid: true
        })
        return
      }
      if(!this.ruleInfo.ruleLineList || this.ruleInfo.ruleLineList.length <= 0){
        this.$bvToast.toast("请填写规则",{
          title: `提示`,
          variant: 'danger',
          solid: true
        })
        return
      }
      let ruleLineList = []
      for(let i=0;i<this.ruleInfo.ruleLineList.length;i++){
        if(!this.ruleInfo.ruleLineList[i].ruleType || !this.ruleInfo.ruleLineList[i].ruleTypeCondition || !this.ruleInfo.ruleLineList[i].ruleValue){
          this.$bvToast.toast(`第${i+1}条规则格式错误`,{
            title: `提示`,
            variant: 'danger',
            solid: true
          })
          return
        }
        ruleLineList.push({
          ruleType: this.ruleInfo.ruleLineList[i].ruleType.enumName,
          ruleTypeCondition: this.ruleInfo.ruleLineList[i].ruleTypeCondition.enumName,
          ruleValue: this.ruleInfo.ruleLineList[i].ruleValue
        })
      }
      let userIdList = []
      for(let i=0;i<this.ruleInfo.userList.length;i++){
        userIdList.push(
          this.ruleInfo.userList[i].id
        )
      }
      let ruleForm = {
        id: this.ruleInfo.id,
        type: this.ruleInfo.type.enumName,
        businessType: this.ruleInfo.businessType.enumName,
        ruleLineList: ruleLineList,
        userIdList: userIdList,
        note: this.ruleInfo.note
      }
      console.log('ruleForm',ruleForm)
      request.post('tob/rule/bRule/save', ruleForm).then(res => {
        console.log('RES',res)
        if (res.data.success) {
          this.$bvToast.toast("操作成功",{
            title: `提示`,
            variant: 'success',
            solid: true
          })
          this.$router.back()
        }
      })
    },
    back(){
      this.$router.back()
    },
    // 完成分组选择是触发
    groupChangeHandle(){
      let shouUpdate = true
      for(let i=0;i<this.ruleInfo.ruleLineList.length;i++){
        if(this.ruleInfo.ruleLineList[i].ruleType.enumName == "GROUP" && this.ruleInfo.ruleLineList[i].ruleValue == this.editCheckdGroup.id && i != this.nowChangeGroupIndex){
          // 重复选择
          shouUpdate = false
          this.$bvToast.toast("分组重复,请重新选择",{
            title: `提示`,
            variant: 'danger',
            solid: true
          })
        }
      }
      if(shouUpdate){
        this.ruleInfo.ruleLineList[this.nowChangeGroupIndex].ruleLabel = this.editCheckdGroup.name
        this.ruleInfo.ruleLineList[this.nowChangeGroupIndex].ruleValue = this.editCheckdGroup.id
      }
    },
    // 完成标签选择时触发
    ruleValueHandleChange(index,val){
      let shouUpdate = true
      for(let i=0;i<this.ruleInfo.ruleLineList.length;i++){
        if(this.ruleInfo.ruleLineList[i].ruleType.enumName && this.ruleInfo.ruleLineList[i].ruleType.enumName == "TAG" && this.ruleInfo.ruleLineList[i].ruleValue == val.id && i != index){
          // 重复选择
          shouUpdate = false
          this.$bvToast.toast("标签重复,请重新选择",{
            title: `提示`,
            variant: 'danger',
            solid: true
          })
        }
      }
      if(shouUpdate){
        this.ruleInfo.ruleLineList[index].ruleValue = val.id
        this.ruleInfo.ruleLineList[index].ruleLabel = val.name
      }
    },
    ruleTypeHandleChange(item,val){
      item.ruleTypeCondition = this.getTypeConditionByRuleType(val)[0]
      item.ruleValue = null
      item.ruleLabel = null
      console.log('ruleTypeCondition',item.ruleTypeCondition)
    },
    // 分组树选中节点的改变时触发，主要是作单选的控制
    groupTreeNodeClick(data, node, v){
      this.$refs.groupTree.setCheckedKeys([data.id]);
    },
    groupTreeHandleCheckChange(item,checked,self){
      console.log(item)
      if(checked){
        this.editCheckdGroupTreeId = item.id
        this.$refs.groupTree.setCheckedKeys([item.id])
        this.editCheckdGroup = item
      }else{
        if(this.editCheckdGroupTreeId === item.id){
            this.$refs.groupTree.setCheckedKeys([item.id])
        }
      }
    },
    // 获取分组的树形列表
    getGrooupTree(){
      request.get('tob/tenant/tenantGroup/listTenantGroupsById').then(res => {
        console.log('RES',res)
        if (res.data.success) {
          this.groupTree = res.data.data
          this.groupTreeLock = true
        }
      })
    },
    // 点击分组值输入框时出发,打开分组Modal
    openGroupTreeModal(item,index){
      // 记录要改变的规则是第几个
      this.nowChangeGroupIndex = index
      console.log('index',this.nowChangeGroupIndex)
      if(!this.groupTreeLock){
        this.getGrooupTree()
      }
      if(item && item.ruleValue){
        this.groupTreeDefaultCheckedKeys = [item.ruleValue]
        this.editCheckdGroupTreeId = item.ruleValue
      }
      this.$nextTick(() => {
        this.$bvModal.show('modal-groupTree-closing')
      })
    },
    // 添加规则
    addRule(){
      this.ruleInfo.ruleLineList.push({ruleLabel:"",ruleValue:""})
    },
    // 获取规则详情
    getRuleInfo(ruleId){
      request.get('tob/rule/bRule/info', {ruleId}).then(res => {
        console.log('RES',res)
        if (res.data.success) {
          this.ruleInfo = res.data.data
          console.log('ruleInfo',this.ruleInfo)
        }
      })
    },
    // 删除单行规则
    removeItem(index) {
      this.ruleInfo.ruleLineList.splice(index,1)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    // 获取枚举详情
    getRuleEnumInfo(){
      request.get('tob/rule/bRule/enum/list').then(res => {
        console.log('RES',res)
        if (res.data.success) {
          this.ruleEnumInfo = res.data.data
        }
      })
    },
    // 成员分页
    pageAdmin(next,pre){
      if(next)
        this.adminSearchCurrentPage = this.adminSearchCurrentPage + 1
      if(pre)
        this.adminSearchCurrentPage = this.adminSearchCurrentPage - 1

      request.get('tob/user/bAdmin/page', { currentPage: this.adminSearchCurrentPage, pageSize: this.adminSearchPageSize }).then(res => {
        if (res.data.success) {
          if (res.data.success) {
            this.adminOperations = res.data.data.data
            this.adminTotal = res.data.data.count
          }
        }
      })
    },
    // 标签分页
    pageTag(next,pre){
      if(next)
        this.tagSearchCurrentPage = this.tagSearchCurrentPage + 1
      if(pre)
        this.tagSearchCurrentPage = this.tagSearchCurrentPage - 1

      request.get('tob/tag/bTag/page', { currentPage: this.tagSearchCurrentPage, pageSize: this.tagSearchPageSize }).then(res => {
        if (res.data.success) {
          if (res.data.success) {
            this.tagOperations = res.data.data.data
            this.tagTotal = res.data.data.count
          }
        }
      })
    },
  },
}
</script>

<style>
.bottom-line{
  border-bottom: 1px solid rgba(34,41,47,.125);
}
.input-red span{
  color: red !important;
}
input.input-red{
  color: red !important;
}
</style>
